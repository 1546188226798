<template>
    <!-- 新建banner页面 -->
    <div class="positionT0L0">
        <Information :name="information.nickName" :phone="information.userName" :data="information.createTime"></Information>
        <div class="box">
            <div class="leftBox">
                <div style="display: flex;justify-content: space-between">
                    <a-month-picker v-model="monthVal" format="YYYY-MM" picker="month" :disabledDate="disabledDate" @change="monthChange"/>
<!--                    <a-button type="primary" @click="exportClick">导出</a-button>-->
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.datetime"  :loading="tableLoading" :pagination="false">
                        <span slot="descb" slot-scope="text, record">
                            <span>{{ !record.descb ? '--' : record.descb }}</span>
                        </span>
                    </a-table>
                </div>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-if="tableList.length>0"/>
            </div>
            <div class="rightBox">
                <p class="title">近30天学习最多的内容前十排名</p>
                <p class="date">{{ rankingObj.startDay }}-{{ rankingObj.endDay }}</p>
                <a-alert message="" type="warning">
                    <template #description>
                        <p>近30天学习总时长：{{ rankingObj.totalMinut }}</p>
                        <p>近30天学习课程总数：{{ rankingObj.totalCourse }}</p>
                    </template>
                </a-alert>
                <a-table ref="table" :columns="columnsData" :data-source="rankingData" :row-key="record => record.studyTime"  :loading="tableLoading" :pagination="false"></a-table>
                <p class="btn">
                    <a-button type="primary" @click="coursePlayDetails" v-if="isShowCourse">课程播放详情</a-button>
                    <a-button type="primary" @click="vipPlayDetails" v-if="isShowBook">会员书籍播放详情</a-button>
                </p>
            </div>
        </div>
    
        <!--    导出弹框    -->
        <a-modal v-model="visibleShow" title="导出">
            <template #footer>
                <div style="display: flex;justify-content: flex-end">
                    <a-button @click="visibleShow = false" class="margin_right20">取消</a-button>
                    <download-excel
                        :data="exportList"
                        :fields="tableDataName"
                        name="近期学习使用数据.xls">
                        <a-button type="primary" @click="visibleShow = false">确定</a-button>
                    </download-excel>
                </div>
            </template>
            <p>
                <a-icon type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
                <span style="margin-left: 10px">确定要导出此数据吗？</span>
            </p>
        </a-modal>
        
        <footer-tool-bar :collapsed="sideCollapsed">
            <a-button @click="$router.go(-1)">返回</a-button>
        </footer-tool-bar>
    </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import MyPagination from "@/components/pagination/MyPagination";
import {StudyRank, UserStudyBehavior} from "@/request/api/userManage";
import Information from "@/components/information/information";
import {codeFn} from "@/utils/tools";
import moment from 'moment';

export default {
    mixins: [baseMixin],
    components: {MyPagination,FooterToolBar,Information},
    created() {
        
        if (codeFn("/adminv2/playvideo/userCoursePlayDetail/course")) this.isShowCourse = true;
        if (codeFn("/adminv2/playvideo/userCoursePlayDetail/book")) this.isShowBook = true;
        
        this.getTagList()
        this.rankingList()
        
        let date = new Date();        //获取系统时间
        let year = date.getFullYear();
        let month= date.getMonth() + 1;
        month= month< 10 ? ('0' + month) : month;
        this.monthVal =  year + '-' + month;
    },
    data() {
        return {
            isShowCourse:false,
            isShowBook:false,
            
            dateStrings:'',
            rankingObj:{},
            tableLoading:false,
            visibleShow:false,
            // 近期学习使用行为
            columns: [
                {
                    title: "时间",
                    dataIndex: "datetime",
                    key: "datetime",
                },
                {
                    title: "行为类型",
                    dataIndex: "behavior",
                    key: "behavior",
                },
                {
                    title: "行为描述",
                    dataIndex: "descb",
                    key: "descb",
                    scopedSlots: {customRender: "descb"},
                },
                {
                    title: "设备类型",
                    dataIndex: "deviceType",
                    key: "deviceType",
                },
                {
                    title: "系统类型",
                    dataIndex: "osType",
                    key: "osType",
                },
                {
                    title: "系统版本",
                    dataIndex: "osVersion",
                    key: "osVersion",
                },
                {
                    title: "APP版本",
                    dataIndex: "appVersion",
                    key: "appVersion",
                },
            ],
            tableList: [],
            // 学习内容排行
            columnsData: [
                {
                    title: "排名",
                    dataIndex: "rank",
                    key: "rank",
                },
                {
                    title: "学习内容",
                    dataIndex: "courseName",
                    key: "courseName",
                },
                {
                    title: "学习时长",
                    dataIndex: "studyTime",
                    key: "studyTime",
                }
            ],
            rankingData:[],
            // 分页
            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0, // 列表的总条数
            information:{},
            
            // 导出
            exportList:[],
            tableDataName:{
                "时间": "datetime",
                "行为类型": "behavior",
                "行为描述": "descb",
            },
        };
    },

    methods: {
        moment,
        disabledDate(current) {
            // 不可选过去时间
            // return current < moment().add(-1, 'd');
            // 不可选未来时间
            return current && current > moment().add(0, "d");
        },
        
        // 监听选择时间onchange事件
        monthChange(dates,dateStrings){
            this.tableList = []
            this.pageNo = 1
            this.dateStrings = dateStrings
            this.getTagList()
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            console.log(current,pageSize)
            this.getTagList()
        },

        //课程播放详情
        coursePlayDetails(){
            let obj = {
                userNo:this.$route.params.id,
                cateType:2,
            }
            let id = JSON.stringify(obj)
            this.$router.push(`/userManage/playDataList/CoursePlayList/${id}`);
        },

        //会员书籍播放详情
        vipPlayDetails(){
            let obj = {
                userNo:this.$route.params.id,
                cateType:1,
            }
            let id = JSON.stringify(obj)
            this.$router.push(`/userManage/playDataList/BooksPlayList/${id}`);
        },
    
        // 近期学习使用行为导出
        exportClick() {
            UserStudyBehavior({
                userNo:this.$route.params.id,
                month:this.monthVal,
                pageNo: this.pageNo,
                pageSize: 0,
            }).then((res) => {
                if (res.code === 200) {
                    this.exportList = res.data.list
                    if(this.exportList.length>0){
                        this.visibleShow = true
                    }else{
                        this.$message.warning('暂无导出数据')
                    }
                }
            });
        },
    
        // 近期学习使用行为
        getTagList() {
            let yers = this.dateStrings.slice(0,4)
            let month = this.dateStrings.slice(5,7)
            let monthVal = yers+month
            this.tableLoading = true;
            UserStudyBehavior({
                userNo:this.$route.params.id,
                month:monthVal,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                    this.information = res.data.userData
                }
                this.tableLoading = false;
            });
        },
    
        // 学习内容排行
        rankingList() {
            this.tableLoading = true;
            StudyRank({
                userNo:this.$route.params.id,
            }).then((res) => {
                if (res.code === 200) {
                    this.rankingObj = res.data
                    this.rankingData = res.data.rankList
                }
                this.tableLoading = false;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.box{
    display: flex;
    .leftBox{
        flex: 1;
        width: 60%;
        margin-top: 15px;
        .contant-box{
            margin-bottom: 15px;
        }
    }
    .rightBox{
        margin-top: 10px;
        margin-left: 15px;
        width: 40%;
        display: flex;
        flex-direction: column;
        border-left: 1px dashed rgba(231, 231, 231, 1);
        padding-left: 15px;
        .date{
            margin: 8px 0;
        }
        .btn{
            display: flex;
            justify-content: space-around;
            margin-top: 15px;
        }
    }
}


</style>
